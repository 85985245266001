import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import slugify from 'slugify'
import styles from './post-detail.module.scss'
import Link from '../link'
import ResponsiveImage from '../responsive-image'

const PostDetail = ({ image, title, link, taxonomy, year, notes }) => {
  const tags = taxonomy.tag ? taxonomy.tag : []
  const publisher =
    taxonomy.publisher && taxonomy.publisher.length
      ? taxonomy.publisher.join('/')
      : 'unknown'

  const developer =
    taxonomy.developer && taxonomy.developer.length
      ? taxonomy.developer.join('/')
      : 'unknown'

  const platform = taxonomy.platform ? taxonomy.platform[0] : undefined

  return (
    <div className={styles.el}>
      {image && (
        <ResponsiveImage
          src={image}
          alt={title}
          key={title}
          loader
          outerClass={styles.el__img}
        />
      )}
      <div className={styles.el__infoWrapper}>
        <div className={styles.el__caption}>
          <h3 className={styles.el__title}>
            {link ? (
              <a href={link} target="_blank" rel="noopener noreferrer">
                {title}
              </a>
            ) : (
              title
            )}
          </h3>
          <p className={styles.el__description}>
            {platform}
            {platform && year ? `, ` : ``}
            {year ? `${year}.` : ``}
            {developer === publisher
              ? ` Game developed and published by ${developer}.`
              : ` Game developed by ${developer} and published by ${publisher}.`}
          </p>
          {notes && (
            <p className={styles.el__notes}>{ReactHtmlParser(notes)}</p>
          )}
          {!!tags.length && (
            <div className={styles.el__tags}>
              {tags.map(tag => {
                const slug = slugify(tag.toString(), { lower: true })
                return (
                  <li className={styles.el__tags__item} key={slug}>
                    <Link to={`/tag/${slug}`} className={styles.el__tags__link}>
                      {tag}
                    </Link>
                  </li>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

PostDetail.defaultProps = {
  image: '',
  title: '',
  link: '',
  taxonomy: {
    developer: [],
    publisher: [],
    tag: [],
    platform: [],
  },
  year: '',
  notes: '',
}

PostDetail.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  taxonomy: PropTypes.object,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  notes: PropTypes.string,
}

export default PostDetail
