import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import mediaQueries from '../../styles/resources/media-queries.scss'
import styleConfig from '../../styles/resources/config.scss'
import styles from './grid.module.scss'

const Grid = ({ children, layout }) => {
  const isClient = typeof window === 'object'
  const [evenColumns, setEvenColumns] = useState(undefined)
  const [containerWidth, setContainerWidth] = useState(undefined)
  const gridSizeD = parseInt(styleConfig.gridSizeD, 10)
  const gridSizeM = parseInt(styleConfig.gridSizeM, 10)

  const isTabletLandscapeBelow = isClient
    ? useMediaQuery({ query: mediaQueries.tabletLandscapeBelow })
    : false

  const handleResize = () => {
    const columnWidth = isTabletLandscapeBelow ? gridSizeM : gridSizeD
    const numCols = Math.floor(
      (window.innerWidth - columnWidth * 0.4) / columnWidth
    )
    setEvenColumns(numCols % 2 === 0 ? 'even' : 'odd')
    setContainerWidth(`${columnWidth * numCols}px`)
  }

  useEffect(() => {
    if (!isClient) {
      return false
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className={styles.el} data-layout={layout}>
      <div className={styles.el__bg} data-layout={evenColumns}>
        <div className={styles.el__bg__inner} />
      </div>
      <div className={styles.el__container} style={{ width: containerWidth }}>
        {children}
      </div>
    </div>
  )
}

Grid.defaultProps = {
  children: undefined,
  layout: 'default',
}

Grid.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.string,
}

export default Grid
