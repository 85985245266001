import React from 'react'
import PropTypes from 'prop-types'
import Link from '../link'
import styles from './thumb.module.scss'
import ResponsiveImage from '../responsive-image'

const Thumb = ({ title, path, thumb, thumbHover, onClick }) => {
  const handleClick = event => {
    if (onClick !== undefined) {
      event.preventDefault()
      onClick()
    }
  }

  return (
    <div className={styles.el} data-loaded="true">
      <Link
        to={path}
        aria-label={title}
        className={styles.el__link}
        onClick={handleClick}
      >
        {thumb && (
          <ResponsiveImage
            src={thumb}
            alt={title}
            outerClass={styles.el__image}
            fillContainer
          />
        )}
        {thumbHover && (
          <ResponsiveImage
            src={thumbHover}
            alt={title}
            outerClass={styles.el__hover}
            fillContainer
          />
        )}
      </Link>
    </div>
  )
}

Thumb.defaultProps = {
  title: '',
  path: '',
  thumb: '',
  thumbHover: '',
  onClick: undefined,
}

Thumb.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  thumb: PropTypes.string,
  thumbHover: PropTypes.string,
  onClick: PropTypes.func,
}

export default Thumb
